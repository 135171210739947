@font-face {
  font-family: "Roobert";
  font-weight: 300;
  src: local("Roobert"), url("../theme/fonts/roobert/Roobert-Light.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Light.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 300;
  font-style: italic;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Light.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Light.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 400;
  src: local("Roobert"), url("../theme/fonts/roobert/Roobert-Regular.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Regular.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 400;
  font-style: italic;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Regular.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Regular.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 500;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Medium.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Medium.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 500;
  font-style: italic;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Medium.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Medium.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 600;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-SemiBold.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 600;
  font-style: italic;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-SemiBold.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 700;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Bold.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Bold.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 700;
  font-style: italic;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Bold.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Bold.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 900;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Heavy.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Heavy.woff") format("woff");
}
@font-face {
  font-family: "Roobert";
  font-weight: 900;
  font-style: italic;
  src: local("Roobert") url("../theme/fonts/roobert/Roobert-Heavy.woff2") format("woff2"),
    url("../theme/fonts/roobert/Roobert-Heavy.woff") format("woff");
}
